export const PRODUCT_CARD_WIDTH = 300;
export const PRODUCT_CARD_HEIGHT = 300;

export const CATEGORY_IMAGE_WIDTH = 375;
export const CATEGORY_IMAGE_HEIGHT = 282;

export const BANNER_IMAGE_WIDTH = 1165;
export const BANNER_IMAGE_HEIGHT = 509;

export const MOBILE_CATEGORY_IMAGE_WIDTH = 360;
export const MOBILE_CATEGORY_IMAGE_HEIGHT = 217;

export const MOBILE_BANNER_IMAGE_WIDTH = 358;
export const MOBILE_BANNER_IMAGE_HEIGHT = 351;

export const TESTIMONIALS_IMAGE_WIDTH = 94;
export const TESTIMONIALS_IMAGE_HEIGHT = 94;

export const MOBILE_TESTIMONIALS_IMAGE_WIDTH = 44;
export const MOBILE_TESTIMONIALS_IMAGE_HEIGHT = 44;

export const DEFAULT_LAYOUT_SELECTION = 12;
export const DEFAULT_NAVBAR_LAYOUT_SELECTION = 'PH2';
export const DEFAULT_SLIDER_LAYOUT_SELECTION = 'HB4';
export const DEFAULT_PRODUCTLIST_LAYOUT_SELECTION = 'PS1';
export const DEFAULT_CATEGORYLIST_LAYOUT_SELECTION = 'CS14';
export const DEFAULT_TESTIMONIALS_LAYOUT_SELECTION = 'Testimonial6';
export const DEFAULT_TEXT_LAYOUT_SELECTION = 'Text1';

export const SHOW_ALL_PRODUCTS_IN_HOME = false;

export const SECTION_HEADING_CLASSNAME = 'capitalize font-bold';
export const SLIDER_TOP_PADDING = 'Pt-20 md:pt-30';
export const LAYOUT_CONFIG = {
  productList: {
    ShowTaxTag: false,
    showDiscountLabel: false,
  },
};
export const COMMON_TOAST_MESSAGE = {};
