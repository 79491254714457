import { ToastWithKeyType } from '@common/store/toasts/slice';
import { addToast, removeToast } from '@common/store/toasts/slice';
import Errorimg from '@images/ErrorToast.svg';
import Success from '@images/SuccessToast.svg';
import ToastCloseIcon from '@images/toastClose.svg';
import ToastCloseIconGreen from '@images/toastcloseGreen.svg';
import classnames from 'classnames';
// import ToastCloseIcon from '@images/toastClose.svg';
import React from 'react';

export interface ToastsProps {
  toasts: ToastWithKeyType[];
  addToast: typeof addToast;
  removeToast: typeof removeToast;
  quickView?: boolean;
}

const Toasts: React.FC<ToastsProps> = ({ toasts, removeToast, quickView }) => {
  setTimeout(() => {
    if (toasts.length) {
      document.getElementById('QuickViewToasts')?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, 1000);

  return (
    <div className={classnames('', { 'mt-30 ': !quickView, 'min-w-full': quickView })}>
      {toasts.map((toast) => (
        <div key={toast.key} className={'text-black mb-10 last:mb-0'}>
          <div
            className={classnames('container mx-auto w-full flex items-center justify-start ', {
              'px-0': quickView,
              'px-16 md:px-100': !quickView,
            })}
          >
            <div className="flex w-full rounded-5">
              <div className={classnames('flex rounded-5 w-full', {})}>
                <div
                  className={classnames('rounded-l-5', {
                    'bg-errorToast h-auto min-w-44 items-center justify-center flex bg-opacity-70':
                      toast.variant === 'bg-toast-error',
                    'bg-successToast h-auto min-w-44 items-center justify-center flex bg-opacity-70':
                      toast.variant != 'bg-toast-error',
                  })}
                >
                  {toast.variant != 'bg-toast-error' ? (
                    <img src={Success} alt="Success" className="" />
                  ) : (
                    <img src={Errorimg} alt="Error" className="" />
                  )}
                </div>
                <div
                  className={classnames({
                    'bg-errorToast pl-14 md:pl-40 pr-28 py-12 rounded-r-5 w-full flex justify-between items-center bg-opacity-30':
                      toast.variant === 'bg-toast-error',
                    'bg-successToast pl-14 md:pl-40 pr-28 py-12 flex rounded-r-5 w-full justify-between items-center bg-opacity-30':
                      toast.variant != 'bg-toast-error',
                  })}
                >
                  <div>
                    <div
                      className={classnames({
                        'text-errorToast font-bold mr-10 text-14 md:text-16 leading-20 md:leading-26':
                          toast.variant === 'bg-toast-error',
                        'text-successToast font-bold mr-10 text-14 md:text-16 leading-20 md:leading-26':
                          toast.variant != 'bg-toast-error',
                      })}
                      font-customisation="para-text"
                    >
                      {toast.variant === 'bg-toast-error' ? 'Error!' : 'Success!'}
                    </div>
                    <div
                      className="text-black text-14 md:text-16 leading-20 md:leading-26 mr-10 md:mr-20 md:w-710"
                      font-customisation="para-text"
                      dangerouslySetInnerHTML={{ __html: toast.content }}
                    ></div>
                  </div>
                  <div onClick={() => removeToast(toast.key)}>
                    {toast.variant != 'bg-toast-error' ? (
                      <img src={ToastCloseIconGreen} alt="Success" className="cursor-pointer my-18 min-w-10 min-h-10" />
                    ) : (
                      <img src={ToastCloseIcon} alt="Error" className="cursor-pointer my-18 min-w-10 min-h-10" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Toasts;
